import sixteenHarmonics from "../../../../src/posts/tanpuraworking-1/16harmonics.dsp";
import twoCloseTones from "../../../../src/posts/tanpuraworking-1/Two-close-tones.dsp";
import twoCloseStrings from "../../../../src/posts/tanpuraworking-1/Two-close-strings.dsp";
import tanpuraString from "../../../../src/posts/tanpuraworking-1/tanpura-string.dsp";
import * as React from 'react';
export default {
  sixteenHarmonics,
  twoCloseTones,
  twoCloseStrings,
  tanpuraString,
  React
};