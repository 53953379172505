import droneSamaGana from 'data/default.prt';
import scaleSamaGana from 'data/default.pkb';
import droneShadajGram from "../../../../src/posts/musicalscales/shadajgram.prt";
import scaleShadajGram from "../../../../src/posts/musicalscales/shadajgram.pkb";
import * as React from 'react';
export default {
  droneSamaGana,
  scaleSamaGana,
  droneShadajGram,
  scaleShadajGram,
  React
};