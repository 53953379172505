import scaleShadajGramAchal from "../../../../src/posts/tuningsystems-3/shadajGramR0.pkb";
import scaleShadajGramR1 from "../../../../src/posts/tuningsystems-3/shadajGramR1.pkb";
import scaleShadajGramR2 from "../../../../src/posts/tuningsystems-3/shadajGramR2.pkb";
import scaleShadajGramR3 from "../../../../src/posts/tuningsystems-3/shadajGramR3.pkb";
import scaleShadajGramR4 from "../../../../src/posts/tuningsystems-3/shadajGramR4.pkb";
import * as React from 'react';
export default {
  scaleShadajGramAchal,
  scaleShadajGramR1,
  scaleShadajGramR2,
  scaleShadajGramR3,
  scaleShadajGramR4,
  React
};